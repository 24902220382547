import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { getTopTen } from "store/token-actions";
import { IoMdClose } from "react-icons/io";
import "./TopTen.css";
import TopTenItem from "./TopTenItem";

const TopTen = ({ stakeContract }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [toptenList, settoptenList] = useState([]);
  const dispatch = useDispatch();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (stakeContract) {
      dispatch(getTopTen(stakeContract)).then((res) => {
        // let addreses = [...res.TopTen];
        // let amounts = [...res.TopTenAmount];
        // let toptensReversed = {
        //   toptenAddress: addreses.reverse(),
        //   toptenAmounts: amounts.reverse(),
        // };

        settoptenList(res);
      });
    }
  }, [stakeContract]);

  return (
    <>
      <button className="topten-action" onClick={openModal}>
        Leaderboard
      </button>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <button className="close-btn" onClick={closeModal}>
            <IoMdClose />
          </button>
          <div className="topten-list">
            <div className="topten-list__title">
              <span>Top 10 Wallets</span>
            </div>
            {toptenList.toptenAddress &&
              toptenList.toptenAddress.map((item, index) => (
                <TopTenItem
                  key={index}
                  index={index}
                  itemAddress={item}
                  itemAmount={toptenList.toptenAmounts[index]}
                />
              ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default TopTen;
