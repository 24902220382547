import React from "react";
// import { useState } from "react";
// import Modal from "react-modal";
import {useDispatch} from "react-redux";
import {setUnStakeTime} from "store/admin-actions";
// import DateTimePicker from "react-datetime-picker";
import "./Unstake.css";

const Unstake = ({stakeContract2, account}) => {
  const dispatch = useDispatch();

  const setUnStakeTimeHandler = () => {
    dispatch(setUnStakeTime(stakeContract2, account));
  };

  return (
    <>
      <button onClick={setUnStakeTimeHandler}>Set Unstake Time</button>
      {/* {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <DateTimePicker
            maxDetail="second"
            openWidgetsOnFocus={false}
            className="datetime-picker"
            onChange={onChange}
            value={value}
          />
          <button onClick={setUnStakeTimeHandler}>submit</button>
        </Modal>
      )} */}
    </>
  );
};

export default Unstake;
