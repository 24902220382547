import "./Dashboard.css";
import React, {useEffect, useState} from "react";
import MainCard from "../components/layout/MainCard";
import useContract from "../hooks/use-contract";
import stakeAbi from "../assets/files/Staking.json";
import stakeAbi2 from "../assets/files/Staking2.json";
// import BULCStakeAbi from "../assets/files/staking_BULCTest";
import BULCStakeAbi from "../assets/files/Staking2.json";
import {addresses} from "../modules/addresses";
import BUSDBountyPositions from "../components/dashboard/BUSDBountyPositions";
import BUSDCBountyPositions from "../components/dashboard/BUSDCBountyPositions";
import BULCStakePositions from "../components/dashboard/BULCStakePositions";
import TopTen from "components/dashboard/TopTen";

const Home = () => {
  const [stakeContract, setStakeContract] = useState(null);
  const [stakeContract2, setStakeContract2] = useState(null);
  const [BULCStakeContract, setBULCStakeContract] = useState(null);
  const {getContract} = useContract();

  useEffect(() => {
    getContract(stakeAbi.abi, addresses.staking_address, (contract) =>
      setStakeContract(contract)
    );
    getContract(stakeAbi2.abi, addresses.staking2_address, (contract) =>
      setStakeContract2(contract)
    );
    getContract(BULCStakeAbi.abi, addresses.staking_BULC_address, (contract) =>
      setBULCStakeContract(contract)
    );
  }, []);

  return (
    <MainCard className="dashboard-container">
      <div className="bulc-stake">
        <span>BLP Farm</span>
      </div>
      <div className="bulc-stake__positions">
        <BULCStakePositions stakeContract={stakeContract} />
      </div>

      <div className="seprator"></div>

      <div className="busd-bounty">
        <div className="busd-bounty__title">
          <span>BUSD Bounty</span>
          <TopTen stakeContract={stakeContract2} />
        </div>
        <BUSDBountyPositions stakeContract={stakeContract2} />
      </div>
      <div className="seprator"></div>

      <div className="busd-bounty">
        <div className="busd-bounty__title">
          <span>BULC Bounty</span>
          <TopTen stakeContract={BULCStakeContract} />
        </div>
        <BUSDCBountyPositions stakeContract={BULCStakeContract} />
      </div>
    </MainCard>
  );
};

export default Home;
