import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fromWei } from "../../modules/web3Wei";
import { getTotalTokenSupply } from "../../store/token-actions";
import "./TotalTokenSupply.css";
import { CommaFormat } from "../../modules/formatNumbers";

const TotalTokenSupply = ({ BULCContract }) => {
  const [totalTokenSupply, settotalTokenSupply] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (BULCContract) {
      dispatch(getTotalTokenSupply(BULCContract))
        .then((res) => {
          settotalTokenSupply(fromWei(res.toString(), "ether"));
        })
        .catch((err) => {
          settotalTokenSupply(0);
        });
    }
  }, [BULCContract]);

  return (
    <div className="bullcoin-state">
      Total Supply
      <span>{CommaFormat(totalTokenSupply)}</span>
    </div>
  );
};

export default TotalTokenSupply;
