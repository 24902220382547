import "./LaunchingBanner.css";
const LaunchingBanner = (props) => {
  return (
    // <div className={"launching-banner " + props.className}>
    //   {props.children}
    // </div>
    <></>
  );
};

export default LaunchingBanner;
