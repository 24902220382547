import { tokenAddresses } from "modules/tokenAdresses";
import { useState, useCallback } from "react";
import Web3 from "web3";

// const web3 = new Web3(
//   Web3.givenProvider ||
//     "https://goerli.infura.io/v3/49b2ce901eeb4d41bc972b31a4a7d1fb" //test
// );

const web3 = new Web3(
  Web3.givenProvider || "https://bsc-dataseed1.binance.org" //test
);

const useBalance = () => {
  const [balance, setBalance] = useState(0);

  const getBalance = useCallback(async (contract, account) => {
    if (contract._address == tokenAddresses[0].address) {
      web3.eth.getBalance(account).then((res) => {
        setBalance(res);
      });
    } else {
      await contract.methods
        .balanceOf(account)
        .call()
        .then((res) => {
          setBalance(res);
        })
        .catch((err) => {
          setBalance(0);
        });
    }
  }, []);

  return {
    balance,
    getBalance,
  };
};

export default useBalance;
