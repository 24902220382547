export const navigationItems = [
  {
    link: "/trade",
    name: "Trade",
  },
  {
    link: "/pool",
    name: "Pool",
  },
  {
    link: "/farm",
    name: "Farm",
  },
  {
    link: "/stake",
    name: "Stake",
  },
  {
    link: "/bounty",
    name: "Bounty",
  },
  {
    link: "/info",
    name: "Info",
  },
  {
    link: "/dashboard",
    name: "My Dashboard",
  },
];
