import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return (
   <div className="not-found"> 
    Not Found.
   </div>
  );
};

export default NotFound;
