import React, {useEffect, useState} from "react";
import MainCard from "../components/layout/MainCard";
import "./Pool.css";
import {addresses} from "../modules/addresses";
import swap_abi from "../assets/files/Swap.json";
import useContract from "../hooks/use-contract";
import PoolForm from "../components/pool/PoolForm";
import LaunchingBanner from "components/layout/LaunchingBanner";

const Pool = () => {
  const [swapContract, setSwapContract] = useState(null);
  const {getContract} = useContract();

  useEffect(() => {
    getContract(swap_abi.abi, addresses.swap_address, (contract) =>
      setSwapContract(contract)
    );
  }, []);

  return (
    <MainCard className="pool-card">
      <PoolForm swapContract={swapContract} />
      <LaunchingBanner>Launching 1st January!</LaunchingBanner>
    </MainCard>
  );
};

export default Pool;
