import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Landing.css";
const Landing = () => {
  return (
    <div className="landing">
      <h1>
        BullCoin is the <span>fuel</span> supporting BullGlobal’s{" "}
        <span>ecosystem</span>.{" "}
      </h1>
      <h2>Trade, Swap, Stake, & Access.</h2>
      <NavLink className="default-button" to="/app" activeClassName="active" exact>
        Launch DApp
      </NavLink>
    </div>
  );
};

export default Landing;
