import React, {useEffect, useState} from "react";
import "./Bounty.css";

import {Toaster} from "react-hot-toast";
import MainCard from "components/layout/MainCard";
import BULC from "../assets/images/BULCFav.svg";
import BUSD from "../assets/images/BNBIcon.svg";
import SNACKS from "../assets/images/SNACKSIcon.svg";
import LaunchingBanner from "components/layout/LaunchingBanner";
import SelectedBounty from "components/bounty/SelectedBounty";

const Bounty = () => {
  const [selectedStakeType, setSelectedStakeType] = useState(""); //BUSD_B & BULC_B

  const selectStakeTypeHandler = (val) => {
    setSelectedStakeType(val);
  };

  var activeElement;
  if (selectedStakeType) {
    activeElement = (
      <SelectedBounty
        selectedBounty={selectedStakeType}
        setSelectStakeType={selectStakeTypeHandler}
      />
    );
  } else {
    activeElement = (
      <>
        <div className="stake-type__actions">
          <div className="stake-type__actions_item">
            <div className="stake-type__actions_item__title">
              <img src={BUSD} alt="" />
              <span>BUSD Bounty</span>
            </div>
            <button onClick={() => selectStakeTypeHandler("BUSD_B")}>
              Enter
            </button>
          </div>

          <div className="stake-type__actions_item">
            <div className="stake-type__actions_item__title">
              <img src={BULC} alt="" />
              <span>BULC Bounty</span>
            </div>
            <button onClick={() => selectStakeTypeHandler("BULC_B")}>
              Enter
            </button>
          </div>

          {/* <div className="stake-type__actions_item">
            <div className="stake-type__actions_item__title">
              <img src={SNACKS} alt="" />
              <span>SNACKS Bounty</span>
            </div>
            <button onClick={() => selectStakeTypeHandler("BUSD_B")} disabled>
              Enter
            </button>
          </div> */}
        </div>
      </>
    );
  }

  return (
    <>
      <MainCard className="staking-main">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="staking-card">{activeElement}</div>
        {/* <LaunchingBanner>Launching 16th November!</LaunchingBanner> */}
      </MainCard>
    </>
  );
};

export default Bounty;
