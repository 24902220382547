import React, { useEffect, useState } from "react";
import useContract from "../hooks/use-contract";
import { addresses } from "../modules/addresses";
import "./AdminDashboard.css";
import stake2_abi from "../assets/files/Staking2.json";
import stakeBULCAbi from "../assets/files/Staking2.json";
import AdminActions from "components/admin/AdminActions";
import ERC20_abi from "assets/files/ERC20.json";

const AdminDashboard = () => {
  const [BUSDStakeContract, setBUSDStakeContract] = useState(null);
  const [BULCStakeContract, setBULCStakeContract] = useState(null);
  const [BUSDContract, setBUSDContract] = useState(null);
  const [BULCContract, setBULCContract] = useState(null);

  const { getContract } = useContract();

  useEffect(() => {
    getContract(stake2_abi.abi, addresses.staking2_address, (contract) => {
      setBUSDStakeContract(contract);
    });
    getContract(ERC20_abi.abi, addresses.BUSD_address, (contract) =>
      setBUSDContract(contract)
    );
    getContract(ERC20_abi.abi, addresses.BULC_address, (contract) =>
      setBULCContract(contract)
    );
    getContract(stakeBULCAbi.abi, addresses.staking_BULC_address, (contract) =>
      setBULCStakeContract(contract)
    );
  }, []);

  return (
    <div className="admin-dashoard">
      <AdminActions
        contract={BUSDStakeContract}
        stakeName={"BUSD Bounty"}
        key={"BUSD-Stake"}
        tokenContract={BUSDContract}
        stakeAddress={addresses.staking2_address}
      />
      <AdminActions
        contract={BULCStakeContract}
        stakeName={"BULC Bounty"}
        disableDuration={true}
        tokenContract={BULCContract}
        stakeAddress={addresses.staking_BULC_address}
      />
    </div>
  );
};

export default AdminDashboard;
