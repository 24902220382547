import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    account: "",
    owner: "0x1EA29df4f4943ef928B0923C5cF528d4fca51403",
  },
  reducers: {
    login(state, action) {
      state.account = action.payload;
    },
    logout(state, action) {
      state.account = "";
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
