import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { addresses } from "../../modules/addresses";
import { fromWei } from "../../modules/web3Wei";
import { getBalanceOf } from "../../store/token-actions";
import "./AdminModals.css";
import { roundNumber } from "../../modules/formatNumbers";

const Witdraw = ({ onWithdraw, pairContract }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const witdrawValue = useRef(0);
  const dispatch = useDispatch();
  const [LPbalance, setLPBalance] = useState(0);

  const witdrawOpenModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (pairContract) {
      dispatch(getBalanceOf(pairContract, addresses.staking2_address)).then(
        (res) => {
          setLPBalance(res);
        }
      );
    }
  }, [pairContract]);

  return (
    <>
      <button onClick={witdrawOpenModal}>Withdraw</button>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <div className="LP-token-balance">
            <p>Balance :</p>
            <p>{roundNumber(fromWei(LPbalance, "ether"), 5)} BUSD-BULC LP</p>
          </div>
          <form>
            <input ref={witdrawValue} placeholder="value" />
            <div className="actions">
              <button className="cansel-btn" onClick={closeModal}>
                Cancel
              </button>
              <button
                className="submit-btn"
                type="button"
                onClick={() => onWithdraw(witdrawValue)}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default Witdraw;
