import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {roundNumber, shortAccountAddress} from "../../modules/formatNumbers";
import {fromWei} from "../../modules/web3Wei";
import {
  getIsStartedGetReward,
  getIsStartedWithdraw,
  getNewStakePositions,
  getReward,
  unstakePosition,
} from "../../store/stake-actions";
import "./BUSDBountyPositions.css";

const BUSDBountyPositions = ({stakeContract}) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);
  const [postion, setPostion] = useState({
    percentShares: 0,
    shares: 0,
    reward: 0,
  });
  const [isStartedWitdraw, setIsStartedWitdraw] = useState(false);
  const [isStarteGetReward, setIsStarteGetReward] = useState(false);

  useEffect(() => {
    const getNewStakePosition = async () => {
      await dispatch(getNewStakePositions(stakeContract, account))
        .then((res) => {
          setPostion(res);
        })
        .catch((err) => {
          console.log("no psition");
        });

      await dispatch(getIsStartedWithdraw(stakeContract)).then((res) => {
        setIsStartedWitdraw(res);
      });
      await dispatch(getIsStartedGetReward(stakeContract, account)).then(
        (res) => {
          setIsStarteGetReward(res);
        }
      );
    };
    if (stakeContract && account) {
      getNewStakePosition();
    }
  }, [stakeContract, account]);

  const unstakePositionHandler = async (index) => {
    try {
      let res = await dispatch(unstakePosition(account, stakeContract));
      let returnValues = res.events.Unstake.returnValues;

      toast.success(
        "Unstake Was Successful !" +
          "\n" +
          "User : " +
          shortAccountAddress(returnValues.user, 3) +
          "\n" +
          "Amount : " +
          roundNumber(fromWei(returnValues.amountStaked, "ether"), 5) +
          " LP" +
          "\n" +
          "Reward : " +
          roundNumber(fromWei(returnValues.reward, "ether"), 5),
        {
          duration: 100000,
        }
      );
      window.location.reload();
    } catch {
      console.log("error in unstake");
    }
  };

  const getRewardHandler = async () => {
    await dispatch(getReward(account, stakeContract)).then((res) => {
      let returnValues = res.events.rewardAmount.returnValues;

      toast.success(
        "Get Reward Was Successful !" +
          "\n" +
          "Reward Amount : " +
          shortAccountAddress(returnValues.reward, 3),
        {
          duration: 100000,
        }
      );
    });

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <div className="new-staking">
        {/* <BUSDPositionTimer stakeContract2={stakeContract} /> */}
        <div className="staked-lp">
          <span>Staked LP</span>
          <p>{fromWei(postion.shares, "ether")} BLP</p>
        </div>
        <div className="share-pool">
          <span>Share Of Pool</span>
          <p>{roundNumber(fromWei(postion.percentShares, "ether"), 2)}%</p>
        </div>
        {postion.reward > 0 && (
          <div>
            <span>Share Of Reward </span>
            <p> {roundNumber(fromWei(postion.reward, "ether"), 2)}</p>
          </div>
        )}
        <div className="new-staking__btn-container">
          <button
            onClick={getRewardHandler}
            className="unstake-btn"
            disabled={isStarteGetReward > 0 ? false : true}
          >
            Claim Reward
          </button>
          <button
            onClick={unstakePositionHandler}
            className="unstake-btn"
            disabled={
              !isStartedWitdraw ||
              isStarteGetReward != 0 ||
              postion.shares === 0
                ? true
                : false
            }
          >
            Unstake LP
          </button>
        </div>
      </div>
    </>
  );
};

export default BUSDBountyPositions;
