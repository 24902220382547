import React from "react";
import useWeb3 from "../../hooks/use-web3";
import {addresses} from "../../modules/addresses";
import {toWei} from "../../modules/web3Wei";
import "./AddLiquidity.css";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {
  addLiquidityAction,
  addLiquidityEthAction,
} from "../../store/token-actions";
import {tokenAddresses} from "modules/tokenAdresses";
import {Fragment} from "react";

const AddLiquidity = ({coin1, coin2, swapContract, onSupplyLPHandler}) => {
  const {getAllowence, approve} = useWeb3();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);

  const addLiquidity = async (account) => {
    // console.log("coin1.contract", coin1);
    // console.log("coin2.contract", coin2);

    if (coin1.address != tokenAddresses[0].address) {
      await getAllowence(
        coin1.contract,
        account,
        addresses.swap_address,
        async (coin1Allowence) => {
          if (coin1Allowence < Number(toWei(coin1.amount, "ether"))) {
            await approve(
              coin1.contract,
              toWei("100000000000000", "tether"),
              account,
              addresses.swap_address,
              (res) => {
                toast.success(res);
              }
            );
          } else {
            console.log("No Need to Approve coin1");
          }
        }
      );
    }

    if (coin2.address != tokenAddresses[0].address) {
      await getAllowence(
        coin2.contract,
        account,
        addresses.swap_address,
        async (coin2Allowence) => {
          if (coin2Allowence < Number(toWei(coin2.amount, "ether"))) {
            await approve(
              coin2.contract,
              toWei("100000000000000", "tether"),
              account,
              addresses.swap_address,
              (res) => {
                toast.success(res);
              }
            );
          } else {
            console.log("No Need to Approve coin2");
          }
        }
      );
    }

    if (coin1.address == tokenAddresses[0].address) {
      console.log("coin1.address == tokenAddresses[0].address");
      await dispatch(
        addLiquidityEthAction(
          swapContract,
          coin2.address,
          toWei(coin1.amount, "ether"),
          toWei(coin2.amount, "ether"),
          account
        )
      )
        .then((res) => {
          onSupplyLPHandler();
          toast.success("Add Liquidity Was Successful !");
        })
        .catch((err) => {
          toast.error("Add Liquidity Has Error !");
        });
    } else if (coin2.address == tokenAddresses[0].address) {
      console.log("coin2.address == tokenAddresses[0].address");
      await dispatch(
        addLiquidityEthAction(
          swapContract,
          coin1.address,
          // coin2.address,
          toWei(coin1.amount, "ether"),
          toWei(coin2.amount, "ether"),
          account
        )
      )
        .then((res) => {
          onSupplyLPHandler();
          toast.success(res);
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      console.log("else");
      await dispatch(
        addLiquidityAction(
          swapContract,
          coin1.address,
          coin2.address,
          toWei(coin1.amount, "ether"),
          toWei(coin2.amount, "ether"),
          account
        )
      )
        .then((res) => {
          debugger;
          onSupplyLPHandler();
          toast.success(res.toString());
        })
        .catch((err) => {
          debugger;
          toast.error(err.toString());
        });
    }
  };

  return (
    <Fragment>
      <button
        onClick={() => addLiquidity(account)}
        className="main-button supply"
        disabled={!coin1.amount || !coin2.amount}
      >
        Supply LP
      </button>
    </Fragment>
  );
};

export default AddLiquidity;
