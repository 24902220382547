import React from "react";
import {useDispatch} from "react-redux";
import {setStakeTime} from "store/admin-actions";

const SetStake = ({contract, account}) => {
  const dispatch = useDispatch();

  const setStakeTimeHandler = () => {
    dispatch(setStakeTime(contract, account));
  };

  return <button onClick={setStakeTimeHandler}>Set Stake Time</button>;
};

export default SetStake;
