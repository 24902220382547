import React, {useEffect, useState, useCallback} from "react";
import "./SingleStake.css";
import MainCard from "components/layout/MainCard";
import SingleStakeC from "../components/staking/SingleStakeC";
import {useDispatch, useSelector} from "react-redux";
const SingleStake = () => {
  useEffect(() => {
    document.body.classList.add("staking");
    return () => {
      document.body.classList.remove("staking");
    };
  }, []);
  return (
    <MainCard className="staking-main">
      <SingleStakeC />
    </MainCard>
  );
};

export default SingleStake;
