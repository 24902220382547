import { toWei } from "../modules/web3Wei";

export const setStakeTime = (contract, account) => {
  return async (dispatch) => {
    return await contract.methods
      .setStake()
      .send({ from: account })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setUnStakeTime = (contract, account, datetime) => {
  return async (dispatch) => {
    return await contract.methods
      .setWitdraw()
      .send({ from: account })
      .then((res) => {
        console.log("setUnStakeTime", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const witdrawLP = (contract, account, amount) => {
  return async (dispatch) => {
    return await contract.methods
      .witdrawOwnerLP(toWei(amount.toString(), "ether"))
      .send({ from: account })
      .then((res) => {
        console.log("witdrawLP", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setReward = (contract, account, amount) => {
  return async (dispatch) => {
    return await contract.methods
      .setReward(toWei(amount.toString(), "ether"))
      .send({ from: account })
      .then((res) => {
        console.log("setReward", res);
        return Promise.resolve("Operation Was Successful !");
      })
      .catch((err) => {
        return Promise.reject("Operation Rejected !");
      });
  };
};

export const setDuration = (contract, account, datetime) => {
  return async (dispatch) => {
    return await contract.methods
      .setDuration(datetime)
      .send({ from: account })
      .then((res) => {
        console.log("setDuration", res);
        return Promise.resolve("Operation Was Successful !");
      })
      .catch((err) => {
        return Promise.reject("Operation Rejected !");
      });
  };
};

// export const getEndStakeTimestamp = (contract) => {
//   return async (dispatch) => {
//     return await contract.methods
//       .endStakeTimestamp()
//       .call()
//       .then((res) => {
//         console.log("setDuration", res);
//         return Promise.resolve(res);
//       })
//       .catch((err) => {
//         return Promise.reject("Operation Rejected !");
//       });
//   };
// };

export const getEndWitdrawTimestamp = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .endWitdrawTimestamp()
      .call()
      .then((res) => {
        console.log("endWitdrawTimestamp", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject("Operation Rejected !");
      });
  };
};

// export const getEndWithdrawTimestamp = (contract) => {
//   return async (dispatch) => {
//     return await contract.methods
//       .endWitdrawTimestamp()
//       .call()
//       .then((res) => {
//         console.log("getEndWithdrawTimestamp", res);
//         return Promise.resolve(res);
//       })
//       .catch((err) => {
//         return Promise.reject(err);
//       });
//   };
// };

export const getEndStakeTimestampAction = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .endStakeTimestamp()
      .call()
      .then((res) => {
        console.log("getEndStakeTimestampAction", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

