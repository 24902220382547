import React, {useEffect, useState, useCallback} from "react";
import "./SingleStakeC.css";
import ScriptTag from "react-script-tag";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import LPToeknBalance from "pages/LPToeknBalance";
const SingleStakeC = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-2.2.4.min.js"
          integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44="
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/gh/ethereum/web3.js@1.0.0-beta.36/dist/web3.min.js"
          integrity="sha256-nWBTbvxhJgjslRyuAKJHK+XcZPlCnmIAAMixz6EefVk="
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/web3/1.6.1-rc.0/web3.min.js"
          integrity="sha512-+4Gl8sbHe5qD5EQiPtIva4be9TwUmmzgYJUdGbhsLYNegQw4Quda/4QdZpJzx8cHq2s1F4PoIpAnERQ0hHTcNQ=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        ></script>
        <script src="https://cdn.jsdelivr.net/npm/bignumber.js@9.0.2/bignumber.min.js"></script>
        <script
          type="text/javascript"
          src="https://unpkg.com/web3@1.2.11/dist/web3.min.js"
        ></script>
        <script
          type="text/javascript"
          src="https://unpkg.com/web3modal@1.9.0/dist/index.js"
        ></script>
        <script
          type="text/javascript"
          src="https://unpkg.com/evm-chains@0.2.0/dist/umd/index.min.js"
        ></script>
        <script
          type="text/javascript"
          src="https://unpkg.com/@walletconnect/web3-provider@1.2.1/dist/umd/index.min.js"
        ></script>
        <script src="https://cdn.jsdelivr.net/npm/@walletconnect/client@1.8.0/dist/esm/index.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@walletconnect/qrcode-modal@1.8.0/dist/umd/index.min.js"></script>

        <script src="https://unpkg.com/@metamask/detect-provider/dist/detect-provider.min.js"></script>
        <script src="/dd.js" defer></script>
      </Helmet>
      <div className="stake_box_holder">
        <a
          href="#"
          id="connect_wallet_btn"
          className="default-button"
          style={{display: "none"}}
        >
          Sign Contract
        </a>{" "}
        <a
          href="#"
          id="logout_btn"
          className="default-button"
          style={{display: "none"}}
        >
          Disconnect
        </a>
        <section id="stake_box">
          <div className="stake_form_field">
            <div>
              <div className="img_row">
                {/* <strong>3 Months - 60% APR</strong> */}
                <span>3 Months</span>
                <span>60% APR</span>
              </div>
              <div className="stake_form_field__input">
                <input
                  data-index="2"
                  id="stake_field_1"
                  name="stake_field_1"
                  placeholder="0"
                  className="input_field"
                  type="text"
                  autoComplete="off"
                />
              </div>
            </div>
            {/* <p className="stake_balance">
              Balance: <i className="balance_bulc">--</i>
              Balance:
            </p> */}
          </div>
          <div className="stake_form_field">
            <div>
              <div className="img_row">
                {/* <strong>6 Months - 80% APR</strong> */}
                <span>6 Months</span>
                <span>80% APR</span>
              </div>
              <div className="stake_form_field__input">
                <input
                  data-index="1"
                  className="input_field"
                  id="stake_field_2"
                  name="stake_field_2"
                  placeholder="0"
                  type="text"
                  autoComplete="off"
                />
              </div>
            </div>
            {/* <p className="stake_balance">
              Balance: <i className="balance_bulc">--</i>
              Balance:
            </p> */}
          </div>
          <div className="stake_form_field">
            <div>
              <div className="img_row">
                {/* <strong>12 Months - 100% APR</strong> */}
                <span>12 Months</span>
                <span>100% APR</span>
              </div>
              <div className="stake_form_field__input">
                <input
                  data-index="0"
                  id="stake_field_3"
                  name="stake_field_3"
                  placeholder="0"
                  className="input_field"
                  type="text"
                  autoComplete="off"
                />
              </div>
            </div>
            {/* <p className="stake_balance">
              Balance: <i className="balance_bulc">--</i>
              Balance:
            </p> */}
          </div>
          <div className="stake_action_bar">
            <button href="#" id="approve_btn" className="approve_btn stake_btn">
              Approve
            </button>
            <button
              href="#"
              id="buy_token_btn"
              className="buy_token_btn stake_btn"
              // style="cursor: not-allowed;"
            >
              Stake
            </button>

            <button
              href="#"
              id="unstake_btn"
              className="buy_token_btn stake_btn"
            >
              Unstake & Claim
            </button>
          </div>
        </section>
        {/* <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://code.jquery.com/jquery-2.2.4.min.js"
      />
      <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://cdn.jsdelivr.net/gh/ethereum/web3.js@1.0.0-beta.36/dist/web3.min.js"
      />
      <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://cdn.jsdelivr.net/npm/web3@1.7.0/dist/web3.min.js"
      />
      <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://unpkg.com/@metamask/detect-provider/dist/detect-provider.min.js"
      />
      <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://cdnjs.cloudflare.com/ajax/libs/web3/1.6.1-rc.0/web3.min.js"
      />
      <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://unpkg.com/moralis@0.0.184/dist/moralis.min.js"
      />
      <ScriptTag
        isHydrating={true}
        type="text/javascript"
        src="https://cdn.jsdelivr.net/npm/bignumber.js@9.0.2/bignumber.min.js"
      />
      <ScriptTag isHydrating={true} type="text/javascript" src="/dd.js" /> */}
      </div>
    </>
  );
};

export default SingleStakeC;
