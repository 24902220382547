import React from "react";
import CoinField from "../coin/CoinField";
import {MdSwapVert} from "react-icons/md";
import SwapSlippageTolerance from "./SwapSlippageTolerance";
import SwapPrice from "./SwapPrice";
import {fromWei} from "../../modules/web3Wei";
import CoinModal from "../coin/CoinModal";

const SwapForm = ({
  onChangeFirstInput,
  onChangeSecInput,
  onChangeSwapState,
  coin1,
  coin2,
  setCoin1,
  setCoin2,
  swapContract,
}) => {
  return (
    <div>
      <CoinField
        tokenImage={coin1.image}
        tokenName={coin1.name}
        tokenAddress={coin1.address}
        tokenContract={coin1.contract}
        coinBalance={coin1.balance}
        coinAmount={coin1.amount}
        onChangeInputHandler={(input) => onChangeFirstInput(input)}
        calculatedAmount={fromWei(coin1.calculatedAmount, "ether")}
      />
      <CoinModal onSetSelectedTokenData={(coin) => setCoin1(coin)} />
      <button className="swap-icon" onClick={onChangeSwapState}>
        {/* <MdSwapVert /> */}
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 11.5L9.5 5.5L3.5 11.5M9.5 26.5V5.5M28.5 20.5L22.5 26.5L16.5 20.5M22.5 5.5V26.5"
            stroke="#C5C1C0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <CoinField
        tokenImage={coin2.image}
        tokenName={coin2.name}
        tokenContract={coin2.contract}
        tokenAddress={coin2.address}
        coinBalance={coin2.balance}
        coinAmount={coin2.amount}
        calculatedAmount={fromWei(coin2.calculatedAmount, "ether")}
        onChangeInputHandler={(input) => onChangeSecInput(input)}
      />
      <CoinModal onSetSelectedTokenData={(coin) => setCoin2(coin)} />
      {/* <SwapPrice
        contract={swapContract}
        coin1Name={coin1.name}
        coin2Name={coin2.name}
        amount={coin1.amount}
        pathAddrress={[coin1.address, coin2.address]}
      /> */}
      <SwapSlippageTolerance />
    </div>
  );
};

export default SwapForm;
