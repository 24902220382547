import { useCallback } from "react";
import Web3 from "web3";

// const web3 = new Web3(
//   Web3.givenProvider ||
//     "https://goerli.infura.io/v3/49b2ce901eeb4d41bc972b31a4a7d1fb" //test
// );

const web3 = new Web3(
  Web3.givenProvider || "https://bsc-dataseed1.binance.org" //test
);

// const web3 = new Web3(window.ethereum);

const useContract = () => {
  const getContract = useCallback(async (abi, address, setContract) => {
    try {
      const initialContract = await new web3.eth.Contract(abi, address);
      setContract(initialContract);
    } catch (err) {
      return Promise.reject("Get Contract Has Error !");
    }
  }, []);

  return {
    getContract,
  };
};

export default useContract;
