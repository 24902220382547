import React, {useEffect, useState} from "react";
import "./Tokenomics.css";
import img from "../assets/images/tokenomics.png";
import img2 from "../assets/images/2.png";
import img3 from "../assets/images/3.png";
import img4 from "../assets/images/4.png";
const Tokenomics = () => {
  return (
    <div className="t-info">
      {/* <div className="learn-left">
        <div className="learn-left__top">
          <div className="learn-left__top-left">
            <h1>Tokenomics</h1>
          </div>
        </div>
      </div> */}
      <img src={img} />
      <img src={img2} />
      <img src={img3} />
      <img src={img4} />
    </div>
  );
};

export default Tokenomics;
