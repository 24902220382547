import React from "react";
import AddLiquidity from "./AddLiquidity";
import RemoveLiquidity from "./RemoveLiquidity";

const PoolActions = ({
  coin1,
  coin2,
  swapContract,
  pairContract,
  onSupplyLPHandler,
  pairAddress,
}) => {
  return (
    <div>
      <div className="pool-actions">
        <AddLiquidity
          coin1={coin1}
          coin2={coin2}
          swapContract={swapContract}
          onSupplyLPHandler={onSupplyLPHandler}
        />
        <RemoveLiquidity
          coin1={coin1}
          coin2={coin2}
          pairContract={pairContract}
          swapContract={swapContract}
          pairAddress={pairAddress}
        />
      </div>
    </div>
  );
};

export default PoolActions;
