import React from "react";
import "./Home.css";
import TokensAmount from "../components/home/TokensAmount";
import BullcoinStates from "../components/home/BullcoinStates";
import PoolRewardAmount from "components/home/PoolRewardAmount";
import stake2_abi from "assets/files/Staking2.json";
import { useEffect } from "react";
import { useState } from "react";
import { addresses } from "modules/addresses";
import useContract from "hooks/use-contract";
import StartWitdrawTimeatamp from "components/home/StartWitdrawTimeatamp";
import { NavLink } from "react-router-dom";
import NewStakeTimer from "components/staking/NewStakeTimer";

const Home = () => {
  const [stakeContract2, setStakeContract2] = useState(null);
  const { getContract } = useContract();

  useEffect(() => {
    getContract(stake2_abi.abi, addresses.staking2_address, (contract) =>
      setStakeContract2(contract)
    );
  }, []);

  return (
    <div className="home-page">
      <p className="home-page__title">
        Bull’s <span>Bounty</span>
      </p>
      {/* <div className="home-page__top">
        <TokensAmount stakeContract2={stakeContract2} />
      </div> */}
      <div className="home-page__bottom">
        <StartWitdrawTimeatamp stakeContract2={stakeContract2} />
        <NewStakeTimer stakeContract2={stakeContract2} />
        {/* <PoolRewardAmount stakeContract2={stakeContract2} /> */}
      </div>
      <div className="home-page__btn-container">
        <NavLink
          className="default-button"
          to="/bounty"
          activeClassName="active"
          exact
        >
          Enter Bounty
        </NavLink>
        <NavLink
          className="default-button"
          to="/learn"
          activeClassName="active"
          exact
        >
          Learn More
        </NavLink>
      </div>

      <div className="home-page__bottom-left-container">
        <div className="home-page__bottom-left">
          <BullcoinStates />
        </div>
      </div>
    </div>
  );
};

export default Home;
