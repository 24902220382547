import React, { useEffect, useState } from "react";
import "./Learn.css";
const Learn = () => {
  return (
    <div className="learn">
      <div className="learn-left">
        <div className="learn-left__top">
          <div className="learn-left__top-left">
            <h1>
              Bull’s <span>Bounty</span>
            </h1>
            <h3>A fun & exciting way to farm rewards!</h3>
          </div>
          <div className="learn-left__top-right">
            <p>
              <span>Timing</span> is everything & to snatch a{" "}
              <span>bigger %</span> of the <span>rewards</span> just deposit
              more LP
            </p>
          </div>
        </div>
        <p className="first-par">
          Pools open on the 1st day of every month for just 24 hours before
          locking
        </p>
        <div className="example">
          <span>Example:</span>
          <p>
            You’re the only user providing 10 BLP in the BUSD Bounty so stand to
            earn 100%
          </p>
          <p>
            Another user enters the pool with 10 BLP of their own, if the pool
            closes at this point, you will split the pool equally 50:50
          </p>
        </div>
        <p className="sec-par">
          The Pool is locked for 1 month, on the last day of the month the
          rewards are distributed and the unstake tab goes live for 24h.{" "}
        </p>
        <p className="third-par">
          Any LP left after this time will automatically be entered into the
          next months bounty{" "}
        </p>
      </div>
      <div className="learn-right">
        <div className="learn-right__item">
          <span>Connect Wallet</span>
          <p>Connect your MetaMask wallet</p>
        </div>
        <div className="learn-right__item">
          <span>Supply LP</span>
          <p>
            Head to “ Pool “ and Supply/create the LP you need for the Bounty
            you have chosen to enter
          </p>
        </div>
        <div className="learn-right__item">
          <span>Select Bounty</span>
          <p>
            Select the Bounty pool you wish to take part in and enter the amount
            of LP you wish to provide
          </p>
        </div>
        <div className="learn-right__item">
          <span>Deposit LP</span>
          <p>
            Deposit the LP created into the Bounty you’ve selected within the
            24h pool open time
          </p>
        </div>
        <div className="learn-right__item">
          <span>Collect Reward</span>
          <p>
            Hit “ Collect Reward “ tab within your My Dashboard. This is where
            you can decide to unstake your LP or enter the next Bounty
          </p>
        </div>
      </div>
    </div>
  );
};

export default Learn;
