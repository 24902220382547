// import FirstStake from "components/staking/OldStake";
import React from "react";
import BULCBounty from "./BULCBounty";
import useContract from "../../hooks/use-contract";
import { useSelector } from "react-redux";
import { addresses } from "../../modules/addresses";
import stakeAbi from "../../assets/files/Staking.json";
import stakeAbi2 from "../../assets/files/Staking2.json";
import pairAbi from "../../assets/files/PairTest.json";
// import pairAbi from "../../assets/files/Pair.json";
import ERC20Abi from "../../assets/files/ERC20.json";
// import ERC20Abi from "../../assets/files/ERC20Test.json";
import { useState } from "react";
import { useEffect } from "react";
import SecStake from "components/staking/NewStake";

const SelectedBounty = ({ selectedBounty, setSelectStakeType }) => {
  const account = useSelector((state) => state.auth.account);

  console.log(selectedBounty);

  const [stakeContract, setStakeContract] = useState(null);
  const [stakeContract2, setStakeContract2] = useState(null);
  const [pairContract, setPairContract] = useState(null);
  const [BUSDContract, setBUSDContract] = useState(null);

  const { getContract } = useContract();

  useEffect(() => {
    getContract(stakeAbi.abi, addresses.staking_address, (contract) =>
      setStakeContract(contract)
    );

    getContract(stakeAbi2.abi, addresses.staking2_address, (contract) =>
      setStakeContract2(contract)
    );

    getContract(pairAbi.abi, addresses.pair_address, (contract) =>
      setPairContract(contract)
    );

    getContract(ERC20Abi.abi, addresses.BUSD_address, (contract) =>
      setBUSDContract(contract)
    );
  }, []);

  if (selectedBounty === "BUSD_B") {
    return (
      // <FirstStake
      //   account={account}
      //   stakeContract={stakeContract}
      //   BUSDContract={BUSDContract}
      //   pairContract={pairContract}
      //   onBackStakingButtons={() => setSelectStakeType("")}
      // />
      <SecStake
        pairContract={pairContract}
        stakeContract={stakeContract2}
        stakeAddress={addresses.staking2_address}
        account={account}
        onBackStakingButtons={() => setSelectStakeType("")}
      />
    );
  } else if (selectedBounty === "BULC_B") {
    return (
      <BULCBounty
        pairContract={pairContract}
        account={account}
        onBackStakingButtons={() => setSelectStakeType("")}
      />
    );
  }
};

export default SelectedBounty;
