import React from "react";
import "./PoolViewContract.css";
import { BiLinkExternal } from "react-icons/bi";

const PoolViewContract = ({ pairAddress }) => {
  return (
    <div className="link-to-address">
      {pairAddress && (
        <a
          className="link"
          href={
            "https://bscscan.com/address/" + //"https://ropsten.etherscan.io/address/" for test
            pairAddress
          }
          target="_blank"
        >
          View Contract
          <BiLinkExternal className="icon" />
        </a>
      )}
    </div>
  );
};

export default PoolViewContract;
