import useContract from "hooks/use-contract";
import useWeb3 from "hooks/use-web3";
import {addresses} from "modules/addresses";
import {toWei} from "modules/web3Wei";
import LPToeknBalance from "pages/LPToeknBalance";
import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import {getIsStartedStake} from "store/stake-actions";
// import stakeAbi from "../../assets/files/staking_BULCTest.json";
import stakeAbi from "../../assets/files/Staking2.json";

const BULKBounty = ({account, pairContract, onBackStakingButtons}) => {
  const [value, setValue] = useState("");
  const [stakeContract, setStakeContract] = useState(null);
  const stakeAddress = addresses.staking_BULC_address;

  const [isStartedStake, setIsStartedStake] = useState(false);

  const dispatch = useDispatch();
  const {getContract} = useContract();

  useEffect(() => {
    getContract(stakeAbi.abi, addresses.staking_BULC_address, (contract) =>
      setStakeContract(contract)
    );
  }, []);

  const changeStakeItem = (e) => {
    setValue(e.target.value);
  };

  const {getAllowence, approve} = useWeb3();

  useEffect(() => {
    if (stakeContract && account) {
      dispatch(getIsStartedStake(stakeContract)).then((res) => {
        setIsStartedStake(res);
      });
    }
  }, [stakeContract, account]);

  const stakeHandler = async () => {
    if (!account) {
      toast.error("Login First !");
      return;
    }

    await getAllowence(
      pairContract,
      account,
      stakeAddress,
      async (pairAllowence) => {
        if (pairAllowence < Number(toWei(value, "ether"))) {
          await approve(
            pairContract,
            toWei("100000000000000", "ether"),
            account,
            stakeAddress,
            (res) => {
              toast.success(res);
            }
          );
        } else {
          console.log("No Need to Approve pair");
        }
      }
    );

    await stakeContract.methods
      .stake(toWei(value, "ether"))
      .send({from: account})
      .then((res) => {
        toast.success("Position Set Successfully !");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="sec-stake_form">
      <div className="stake_form_field">
        <div className="staking-main__top" style={{marginBlock: "20px"}}>
          <LPToeknBalance
            contract={pairContract}
            address={addresses.pair_address}
          />
        </div>

        <div className="sec-stake_form__title">
          <span>BULC Bounty</span>
          <span>50,000.00</span>
        </div>
        <div className="stake_form-group">
          <label className="stake-title">
            <span>1 Month</span>
          </label>
          <input
            data-index="2"
            id="stake_field_1"
            name="stake_field_1"
            placeholder="0"
            type="number"
            value={value}
            onChange={changeStakeItem}
          />
        </div>
      </div>
      <div className="staking-actions">
        <button
          disabled={isStartedStake ? false : true}
          onClick={stakeHandler}
          className="main-button"
        >
          Stake
        </button>
        <button
          className="main-button return-button"
          onClick={onBackStakingButtons}
        >
          Return
        </button>
      </div>
      {/* <LaunchingBanner>Launching 16th November!</LaunchingBanner> */}
    </div>
  );
};

export default BULKBounty;
