import BULC from "../assets/images/BULCFav.svg";
import BUSD from "../assets/images/BNBIcon.svg";
import { addresses } from "./addresses";

export const coins = {
  BULC: {
    name: "BULC",
    abbr: "BULC",
    image: BULC,
    address: addresses.BULC_address,
  },
  BUSD: {
    name: "BUSD",
    abbr: "BUSD",
    image: BUSD,
    address: addresses.BUSD_address,
  },
};
