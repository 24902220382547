import React from "react";
import {useRef} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getEndWitdrawTimestamp} from "store/admin-actions";
import {getUnstakeStartWitdrawTimeatamp} from "store/token-actions";
import "./StartWitdrawTimeatamp.css";

const StartWitdrawTimeatamp = ({stakeContract2}) => {
  const launch = useRef();
  const [timer, setTimer] = useState({});
  const dispatch = useDispatch();
  const [dedline, setDedline] = useState(true);
  const [disableTimer, setdisableTimer] = useState(false);

  const countdownTimer = (timestamp) => {
    launch.current = setInterval(() => {
      let time = getTimeFromDate(timestamp);
      setTimer(time);
    }, 1000);
  };

  const getStartWithdrawTimestamp = () => {
    dispatch(getUnstakeStartWitdrawTimeatamp(stakeContract2)).then((res) => {
      countdownTimer(Number(res) * 1000);
    });
  };

  const getTimeFromDate = (timestamp) => {
    var now = new Date().getTime();
    var timeleft = timestamp - now;

    if (timeleft > 0) {
      setDedline(false);
    }

    var days = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;

    if (timeleft > 0) {
      days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    } else {
      clearInterval(launch.current);
    }

    let time = {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: days,
    };

    return time;
  };

  const getEndStakeTimer = () => {
    dispatch(getEndWitdrawTimestamp(stakeContract2)).then((res) => {
      let timestamp = Number(res) * 1000;
      var now = new Date().getTime();
      var timeleft = timestamp - now;
      if (timeleft > 0) {
        setdisableTimer(true);
      }
    });
  };

  useEffect(() => {
    if (stakeContract2) {
      getEndStakeTimer();
      getStartWithdrawTimestamp();
    }
  }, [stakeContract2]);

  return (
    <>
      {!dedline && !disableTimer && (
        <div className="start-withdraw-timestamp">
          <h5>Pools Open In</h5>
          <div className="deadline-timer__times">
            <div>
              <p>{timer.day}D</p>
            </div>
            <div>
              <p>{timer.hours}H</p>
            </div>
            <div>
              <p>{timer.minutes}M</p>
            </div>
            <div>
              <p>{timer.seconds}S</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StartWitdrawTimeatamp;
