import React, { useState } from "react";
import "./CoinModal.css";
import Modal from "react-modal";
import CoinModalTokenList from "./CoinModalTokenList";
import CoinModalForm from "./CoinModalForm";

const CoinModal = ({ onSetSelectedTokenData }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setSelectedTokenHandler = (data) => {
    onSetSelectedTokenData(data);
    closeModal();
  };

  return (
    <>
      <button onClick={openModal} className="coin-modal__action">
        Change Token
      </button>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <CoinModalForm onSetTokenData={setSelectedTokenHandler} />
          <CoinModalTokenList onSetTokenData={setSelectedTokenHandler} />
        </Modal>
      )}
    </>
  );
};

export default CoinModal;
