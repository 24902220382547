
export const stakes = [
  {
    choice: 1,
    monthNumber: 1,
    APRAmountPersent: 60,
  },
  {
    choice: 2,
    monthNumber: 3,
    APRAmountPersent: 120,
  },
  {
    choice: 3,
    monthNumber: 6,
    APRAmountPersent: 160,
  },
  {
    choice: 4,
    monthNumber: 12,
    APRAmountPersent: 300,
  },
];