import React from "react";
// import { useRef } from "react";
import { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { setDuration } from "store/admin-actions";

const Duration = ({ stakeContract2, account }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  //   const value = useRef(0);
  const [value, onChange] = useState(new Date());

  const dispatch = useDispatch();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setDurationHandler = () => {
    let datetime = value.getTime() / 1000;
    dispatch(setDuration(stakeContract2, account, datetime));
  };

  return (
    <>
      <button onClick={openModal}>Set Duration</button>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <DateTimePicker
            maxDetail="second"
            openWidgetsOnFocus={false}
            className="datetime-picker"
            onChange={onChange}
            value={value}
          />
          <button
            onClick={setDurationHandler}
            className="moda-btn-c"
            style={{ marginTop: "20px" }}
          >
            submit
          </button>
        </Modal>
      )}
    </>
  );
};
export default Duration;
