import React from "react";

import useWeb3 from "hooks/use-web3";
import { toWei } from "modules/web3Wei";
import toast from "react-hot-toast";
import { setReward, witdrawLP } from "store/admin-actions";
import Witdraw from "components/admin/Witdraw";
import Reward from "components/admin/Reward";
import Unstake from "components/admin/Unstake";
import Duration from "components/admin/Duration";
import ERC20_abi from "assets/files/ERC20.json";
import { useDispatch, useSelector } from "react-redux";
import SetStake from "components/admin/SetStake";
import { useEffect } from "react";
import useContract from "hooks/use-contract";
import { useState } from "react";
import { addresses } from "modules/addresses";
import "./AdminActions.css";

const AdminActions = ({
  contract,
  tokenContract,
  stakeAddress,
  stakeName,
  disableDuration,
}) => {
  const { getAllowence, approve } = useWeb3();

  const [pairContract, setPairContract] = useState(null);

  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();
  const { getContract } = useContract();

  useEffect(() => {
    getContract(ERC20_abi.abi, addresses.pair_address, (contract) =>
      setPairContract(contract)
    );
  }, []);

  const withdrawHandler = (input) => {
    let amount = input.current.value;
    dispatch(witdrawLP(contract, account, amount));
  };

  const setRewardHandler = async (input) => {
    let amount = input.current.value;

    await getAllowence(
      tokenContract,
      account,
      stakeAddress,
      async (BUSDAllowence) => {
        if (BUSDAllowence < Number(amount.toString(), "ether")) {
          await approve(
            tokenContract,
            toWei("100000000000000", "tether"),
            account,
            stakeAddress,
            (res) => {
              toast.success(res);
            }
          );
        } else {
          console.log("No Need to Approve BUSD");
        }
      }
    );

    await dispatch(setReward(contract, account, amount))
      .then((res) => {
        toast.success(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <section className="admin-stake-section">
      <h3>{stakeName}</h3>
      <div className="admin-dashoard__actions">
        <SetStake account={account} contract={contract} />
        {!disableDuration && (
          <Duration stakeContract2={contract} account={account} />
        )}
        <Unstake stakeContract2={contract} account={account} />
        <Witdraw onWithdraw={withdrawHandler} pairContract={pairContract} />
        <Reward onReward={setRewardHandler} />
      </div>
    </section>
  );
};

export default AdminActions;
