import React, { useEffect, useState } from "react";
import MainCard from "../components/layout/MainCard";
import "./Swap.css";
import { coins } from "../modules/coins";
import { addresses } from "../modules/addresses";
import SwapPriceImpact from "../components/swap/SwapPriceImpact";
import ERC20_abi from "../assets/files/ERC20.json";
import swapAbi from "../assets/files/Swap.json";
import toast from "react-hot-toast";
// import { roundNumber } from "../modules/formatNumbers";
import useContract from "../hooks/use-contract";
import { fromWei, toWei } from "../modules/web3Wei";
import useWeb3 from "../hooks/use-web3";
import useBalance from "../hooks/use-balance";
import { useDispatch, useSelector } from "react-redux";
import useCoin from "../hooks/use-coin";
import SwapForm from "../components/swap/SwapForm";
import {
  swapETHForExactTokens,
  swapExactETHForTokens,
  swapExactTokensForETH,
  swapTokensForExactEth,
} from "store/token-actions";
import { tokenAddresses } from "modules/tokenAdresses";
import { roundNumber } from "modules/formatNumbers";
import LaunchingBanner from "components/layout/LaunchingBanner";
import Web3 from "web3";

const Swap = () => {
  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();

  const slippageTolerance = useSelector(
    (state) => state.sTol.slippageTolerance
  );

  const { getContract } = useContract();

  const {
    coin: coin1,
    setCoinHandler: setCoin1,
    setCoinValueHandler: setCoin1Value,
  } = useCoin(coins.BUSD);

  const {
    coin: coin2,
    setCoinValueHandler: setCoin2Value,
    setCoinHandler: setCoin2,
  } = useCoin(coins.BULC);

  const [swapContract, setSwapContract] = useState(null);

  useEffect(() => {
    getContract(ERC20_abi.abi, coin1.address, (contract) =>
      setCoin1Value("contract", contract)
    );
    getContract(ERC20_abi.abi, coin2.address, (contract) =>
      setCoin2Value("contract", contract)
    );
  }, []);

  const { balance: token1Balance, getBalance: getToken1Balance } = useBalance();
  const { balance: token2Balance, getBalance: getToken2Balance } = useBalance();

  const updateTokenBalances = () => {
    getToken1Balance(coin1.contract, account);
    getToken2Balance(coin2.contract, account);
  };

  useEffect(() => {
    setCoin1Value("balance", token1Balance);
  }, [token1Balance]);

  useEffect(() => {
    setCoin2Value("balance", token2Balance);
  }, [token2Balance]);

  useEffect(() => {
    getContract(swapAbi.abi, addresses.swap_address, (contract) =>
      setSwapContract(contract)
    );
  }, []);

  const changeFirstInputHandler = async (input) => {
    setCoin1Value("amount", 0);
    setCoin2Value("amount", 0);

    if (!input.value || input.value <= 0) {
      setCoin2Value("calculatedAmount", "");
      return;
    }

    let inputStringValue = input.value.toString();
    let data = toWei(inputStringValue, "ether");

    setCoin1Value("amount", data);

    // console.log("changeFirstInputHandler", data, coin1.address, coin2.address);

    await swapContract.methods
      .getAmountsOut(data, [coin1.address, coin2.address]) //execute amount of second (amountIn, convertible token address, result token address)
      .call()
      .then((res) => {
        setCoin2Value("calculatedAmount", res[1]);
      })
      .catch((err) => {
        // console.log("changeFirstInputHandler has error");
        toast.error("Some Error Happend");
        setCoin1Value("calculatedAmount", "");
      });
  };

  const changeSecInputHandler = async (input) => {
    setCoin1Value("amount", 0);
    setCoin2Value("amount", 0);

    if (!input.value || input.value <= 0) {
      setCoin1Value("amount", 0);
      setCoin2Value("amount", 0);
      setCoin1Value("calculatedAmount", 0);
      return;
    }

    let inputStringValue = input.value.toString();
    let data = toWei(inputStringValue, "ether");

    setCoin2Value("amount", data);

    await swapContract.methods
      .getAmountsIn(data, [coin1.address, coin2.address]) //execute amount of second (amount, convertible token address, result token address)
      .call()
      .then((res) => {
        setCoin1Value("calculatedAmount", res[0]);
      })
      .catch((err) => {
        toast.error("Some Error Happend");
      });
  };

  useEffect(() => {
    if (coin1.contract && coin2.contract && account) {
      updateTokenBalances();
    }
  }, [coin1.contract, account]);

  const swap = async (
    contract,
    methodName,
    amount,
    address1,
    address2,
    amountOut,
    account
  ) => {
    console.log(
      "test",
      amount, // coin1.amount,
      amountOut.toString(),
      [address1, address2],
      account,
      9876543210
    );
    return await contract.methods[methodName](
      amount, // coin1.amount,
      amountOut.toString(),
      [address1, address2],
      account,
      9876543210
    )
      .send({ from: account })
      .then((res) => {
        return Promise.resolve("Swap Was Successful");
      })
      .catch((err) => {
        return Promise.reject("Error in Swap");
      });
  };

  const { getAllowence, approve } = useWeb3();

  const swapFirstCoinFunction = async () => {
    // console.log("swapfirstCoinFunction");

    await getAllowence(
      coin1.contract,
      account,
      addresses.swap_address,
      async (tokenAllowence) => {
        if (tokenAllowence < Number(toWei(coin1.amount, "ether"))) {
          await approve(
            coin1.contract,
            toWei("100000000000000", "tether"),
            account,
            addresses.swap_address,
            (res) => {
              toast.success(res);
            }
          );
        } else {
          console.log("No Need to Approve");
        }
      }
    );

    let calculatedAmount = coin2.calculatedAmount;
    let slippageTolerancePersent = slippageTolerance * (calculatedAmount / 100);

    let BN = Web3.utils.BN;

    const calculatedAmountBigint = new BN(calculatedAmount);
    const slippageToleranceBigint = new BN(slippageTolerancePersent.toString());

    const amountOutMin_string = calculatedAmountBigint.sub(
      slippageToleranceBigint
    );

    if (coin1.address === tokenAddresses[0].address) {
      await dispatch(
        swapExactETHForTokens(
          swapContract,
          coin1.amount,
          Math.ceil(amountOutMin_string),
          tokenAddresses[0].address,
          coin2.address,
          account
        )
      )
        .then((res) => {
          // console.log("swapExactETHForTokens", res);
          toast.success(res);
          updateTokenBalances();
          setCoin1Value("amount", 0);
          setCoin2Value("amount", 0);
        })
        .catch((err) => {
          console.log("error in swapExactETHForTokens", err);
          toast.error("Some Error Happend");
        });
    } else if (coin2.address === tokenAddresses[0].address) {
      dispatch(
        swapExactTokensForETH(
          swapContract,
          coin1.amount,
          Math.ceil(amountOutMin_string),
          tokenAddresses[0].address,
          coin1.address,
          account
        )
      )
        .then((res) => {
          console.log("swapExactTokensForETH", res);
          toast.success(res);
          updateTokenBalances();
          setCoin1Value("amount", 0);
          setCoin2Value("amount", 0);
        })
        .catch((err) => {
          console.log("error in swapExactTokensForETH", err);
          toast.error("Some Error Happend");
        });
    } else {
      // console.log("swapExactTokensForTokens");
      // console.log(
      //   "swapContract",
      //   swapContract,
      //   "coin1.amount",
      //   coin1.amount,
      //   "coin1.address",
      //   coin1.address,
      //   "coin2.address",
      //   coin2.address,
      //   "Math.ceil(amountOutMin_string)",
      //   // Math.ceil(amountOutMin_string),
      //   "account",
      //   account
      // );

      // console.log(amountOutMin_string);
      swap(
        swapContract,
        "swapExactTokensForTokens",
        coin1.amount,
        coin1.address,
        coin2.address,
        amountOutMin_string,
        // '1',
        account
      )
        .then((res) => {
          console.log("swapExactTokensForTokens", res);
          toast.success(res);
          updateTokenBalances();
          setCoin1Value("amount", 0);
          setCoin2Value("amount", 0);
        })
        .catch((err) => {
          console.log("error in swapExactTokensForTokens", err);
          toast.error("Some Error Happend");
        });
    }
  };

  const swapSecCoinFunction = async () => {
    await getAllowence(
      coin1.contract,
      account,
      addresses.swap_address,
      async (tokenAllowence) => {
        if (tokenAllowence < Number(toWei(coin2.amount, "ether"))) {
          await approve(
            coin1.contract,
            toWei("100000000000000", "tether"),
            account,
            addresses.swap_address,
            (res) => {
              toast.success(res);
            }
          );
        } else {
          console.log("No Need to Approve");
        }
      }
    );

    let temp = Number(coin1.calculatedAmount);
    let amountOutMax = temp + (slippageTolerance / 100) * temp;

    const amountOutMax_string = amountOutMax.toLocaleString("fullwide", {
      useGrouping: false,
    });

    if (coin2.address === tokenAddresses[0].address) {
      console.log("swapTokensForExactEth");
      dispatch(
        swapTokensForExactEth(
          swapContract,
          coin2.amount,
          Math.ceil(amountOutMax_string),
          tokenAddresses[0].address,
          coin1.address,
          account
        )
      )
        .then((res) => {
          toast.success(res);
          updateTokenBalances();
          setCoin1Value("amount", 0);
          setCoin2Value("amount", 0);
        })
        .catch((err) => {
          toast.error(err);
        });
    } else if (coin1.address === tokenAddresses[0].address) {
      dispatch(
        swapETHForExactTokens(
          swapContract,
          coin2.amount,
          coin1.address,
          coin2.address,
          coin1.calculatedAmount, //coin1.calamount
          account
        )
      )
        .then((res) => {
          toast.success(res);
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      swap(
        swapContract,
        "swapTokensForExactTokens",
        coin2.amount,
        coin1.address,
        coin2.address,
        amountOutMax_string,
        account
      )
        .then((res) => {
          toast.success(res);
          updateTokenBalances();
          setCoin1Value("amount", "");
          setCoin2Value("amount", "");
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const callingSwapHanlder = () => {
    if (coin1.amount) {
      swapFirstCoinFunction();
    } else if (coin2.amount) {
      swapSecCoinFunction();
    }
  };

  const changeSwapState = async () => {
    let temp = coin1;
    setCoin1(coin2);
    setCoin2(temp);
    setCoin1Value("amount", 0);
    setCoin2Value("amount", 0);
    setCoin1Value("calculatedAmount", 0);
    setCoin2Value("calculatedAmount", 0);
  };

  const insufficientBalance =
    roundNumber(fromWei(coin1.amount, "ether"), 4) >
      roundNumber(fromWei(coin1.balance, "ether"), 4) ||
    roundNumber(fromWei(coin2.amount, "ether"), 4) >
      roundNumber(fromWei(coin2.balance, "ether"), 4);

  return (
    <MainCard className="swap-card">
      <SwapForm
        coin1={coin1}
        coin2={coin2}
        setCoin1={setCoin1}
        setCoin2={setCoin2}
        swapContract={swapContract}
        onChangeFirstInput={changeFirstInputHandler}
        onChangeSecInput={changeSecInputHandler}
        onChangeSwapState={changeSwapState}
      />
      <SwapPriceImpact
        contract={swapContract}
        amount={coin1.amount}
        pathAddrress={[coin1.address, coin2.address]}
      />
      <div className="swap-actions">
        <button
          onClick={callingSwapHanlder}
          className="main-button"
          disabled={insufficientBalance}
        >
          {insufficientBalance ? "Insufficient Balance" : "Swap"}
        </button>
      </div>
      <LaunchingBanner>Launching 1st January!</LaunchingBanner>
    </MainCard>
  );
};

export default Swap;
