export const tokenAddresses = [
  {
    name: "BNB ",
    symbol: "BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    chainId: 56,
    decimals: 18,
    logoURI:
      "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png",
  },
  {
    name: "BULLCOIN",
    symbol: "BULC",
    address: "0x33a12cee993da069bf7ebab3d7423e25278acf61",
    chainId: 56,
    decimals: 18,
  },
  {
    name: "BUSD Token",
    symbol: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    chainId: 56,
    decimals: 18,
  },

  {
    name: "PancakeSwap Token ",
    symbol: "Cake ",
    address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    chainId: 56,
    decimals: 18,
    logoURI:
      "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
  },
  {
    name: "BTCB Token",
    symbol: "BTCB ",
    address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    chainId: 56,
    decimals: 18,
    logoURI: "https://bscscan.com/token/images/btcb_32.png",
  },
];
