import useContract from "hooks/use-contract";
import React from "react";
import { useDispatch } from "react-redux";
import { getNameOfToken } from "../../store/token-actions";
import toast from "react-hot-toast";
import ERC20_abi from "../../assets/files/ERC20.json";
import { useState } from "react";

const CoinModalForm = ({ onSetTokenData }) => {
  const [tokenAddress, setTokenAddress] = useState("");

  const { getContract } = useContract();
  const dispatch = useDispatch();

  const inputChangeHandler = (e) => {
    setTokenAddress(e.target.value);
  };

  const submitAddressHandler = async () => {
    await getContract(ERC20_abi.abi, tokenAddress, async (contract) => {
      await dispatch(getNameOfToken(contract))
        .then((res) => {
          let data = {
            name: res,
            contract: contract,
            address: tokenAddress,
            amount: 0,
          };

          onSetTokenData(data);
          setTokenAddress("");
        })
        .catch((err) => {
          toast.error("Some error happend !");
        });
    }).catch((err) => {
      toast.error("Some error happend !");
    });
  };

  return (
    <div className="coin-modal__form">
      <input
        type="text"
        placeholder="Search name or paste address"
        onChange={inputChangeHandler}
        value={tokenAddress}
      />
      <button
        onClick={submitAddressHandler}
      >
        Submit
      </button>
    </div>
  );
};

export default CoinModalForm;
