import {BigNumber as BigInt} from "bignumber.js";
import {fromWei} from "../modules/web3Wei";
export const getToatalValueLocked = (pairContract) => {
  return async (dispatch) => {
    return await pairContract.methods
      .getReserves()
      .call()
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getTotalTokenSupply = (BULCContract) => {
  return async (dispatch) => {
    return await BULCContract.methods
      .totalSupply()
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getTotalValueLockLPToken = (stakeContract) => {
  return async (dispatch) => {
    return await stakeContract.methods
      .totalValueLockLPToken()
      .call()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getAllStakedBalance = (stakeContract2) => {
  return async (dispatch) => {
    return await stakeContract2.methods
      .allStakedBalance()
      .call()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getPositions = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .getPositions()
      .call()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const unstakePosition = (index, account, stakeContract) => {
  return async (dispatch) => {
    return await stakeContract.methods
      .unstake(index)
      .send({from: account})
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getBalanceOf = (contract, address) => {
  return async (dispatch) => {
    return await contract.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getNameOfToken = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .name()
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getReserves = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .getReserves()
      .call()
      .then((res) => {
        return Promise.resolve({
          reserve0: res._reserve0,
          reserve1: res._reserve1,
        });
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getToken0 = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .token0()
      .call()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getQoute = (contract, amount, reserve0, reserve1) => {
  return async (dispatch) => {
    return await contract.methods
      .quote(amount, reserve0, reserve1)
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getPairAddressFromFactory = (contract, address1, address2) => {
  return async (dispatch) => {
    return await contract.methods
      .getPair(address1, address2)
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const addLiquidityAction = (
  contract,
  address1,
  address2,
  amount1,
  amount2,
  account
) => {
  return async (dispatch) => {
    let num = Math.abs(amount1 - amount2 / (0.5 * 100)).toString();
    //alert(fromWei(amount1, "ether"));

    debugger;
    return await contract.methods
      .addLiquidity(
        address1,
        address2,
        amount1,
        amount2,
        num,
        num,
        account,
        324324324234234
      )
      .send({from: account})
      .then((res) => {
        return Promise.resolve("Add Liquidity Was Successful !");
      })
      .catch((err) => {
        return Promise.reject("Add Liquidity Has Error !");
      });
  };
};

export const addLiquidityEthAction = (
  contract,
  tokenAddress,
  // address2,
  amount1,
  amount2,
  account
) => {
  return async (dispatch) => {
    let num = Math.abs(amount1 - amount2 / (0.5 * 100)).toString();
    //alert(num);
    debugger;
    return await contract.methods
      .addLiquidityETH(
        tokenAddress,
        amount1,
        num,
        num,
        account,
        324324324234234
      )
      .send({from: account, value: amount2})
      .then((res) => {
        return Promise.resolve(true);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const removeLiquidityAction = (
  contract,
  address1,
  address2,
  amount,
  amountMin,
  amountMax,
  account,
  deadline
) => {
  return async (dispatch) => {
    await contract.methods
      .removeLiquidity(
        address1,
        address2,
        amount,
        amountMin,
        amountMax,
        account,
        deadline
      )
      .send({from: account})
      .then((res) => {
        return Promise.resolve(true);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const removeLiquidityETHAction = (
  contract,
  tokenAddress,
  // address2,
  amount,
  amountMin,
  amountMax,
  account,
  deadline
) => {
  return async (dispatch) => {
    await contract.methods
      .removeLiquidityETH(
        tokenAddress,
        amount,
        amountMin,
        amountMax,
        account,
        deadline
      )
      .send({from: account})
      .then((res) => {
        return Promise.resolve(true);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getTopTen = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .topTen()
      .call()
      .then((res) => {
        return Promise.resolve({
          toptenAddress: res.TopTen,
          toptenAmounts: res.TopTenAmount,
        });
      })
      .catch((err) => {
        console.log("error in top ten");
        return Promise.reject(err);
      });
  };
};

export const getStakedReward = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .StakedReward()
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        // console.log("error in staked reward");
        return Promise.reject(err);
      });
  };
};

export const getUnstakeStartWitdrawTimeatamp = (contract) => {
  return async (dispatch) => {
    return await contract.methods
      .duration()
      .call()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const swapExactETHForTokens = (
  contract,
  amount,
  amountMin,
  bnbAddress,
  tokenAddress,
  account
) => {
  return async (dispatch) => {
    console.log(
      "swapExactETHForTokens",
      // amount,
      amount,
      amountMin,
      bnbAddress,
      tokenAddress,
      account
    );
    // console.log(contract);
    return await contract.methods
      .swapExactETHForTokens(
        amountMin.toString(),
        [bnbAddress, tokenAddress],
        account,
        9876543210
      )
      .send({from: account, value: amount})
      .then((res) => {
        // console.log("Swap Was Succsessfull !");
        return Promise.resolve("Swap Was Succsessfull !");
      })
      .catch((err) => {
        // console.log(err);
        // console.log("swapExactETHForTokens has error");
        return Promise.reject("Some Error Happend");
      });
  };
};

export const swapTokensForExactEth = (
  contract,
  amount,
  amountOut,
  bnbAddress,
  address1,
  account
) => {
  return async (dispatch) => {
    console.log(amount, amountOut, bnbAddress, address1);
    return await contract.methods
      .swapTokensForExactETH(
        amount, // tokenAmount,
        amountOut.toString(),
        [address1, bnbAddress],
        account,
        9876543210
      )
      .send({from: account})
      .then((res) => {
        return Promise.resolve("Swap Was Succsessfull !");
      })
      .catch((err) => {
        return Promise.reject("Some Error Happend");
      });
  };
};

export const swapExactTokensForETH = (
  contract,
  amount,
  amountOutMin,
  bnbAddress,
  tokenAddress,
  account
) => {
  return async (dispatch) => {
    console.log("swapExactTokensForETH");
    console.log(amount, amountOutMin, bnbAddress, tokenAddress);
    return await contract.methods
      .swapExactTokensForETH(
        amount, // tokenAmount,
        amountOutMin,
        [tokenAddress, bnbAddress],
        account,
        9876543210
      )
      .send({from: account})
      .then((res) => {
        return Promise.resolve("Swap Was Successful !");
      })
      .catch((err) => {
        return Promise.reject("Some Error Happend");
      });
  };
};

export const swapETHForExactTokens = (
  contract,
  amount,
  bnbAddress,
  tokenAddress,
  calcAmount, //coin1.calamount
  account
) => {
  return async (dispatch) => {
    console.log("swapETHForExactTokens");
    console.log(
      amount,
      bnbAddress,
      tokenAddress,
      calcAmount //coin1.calamount
    );
    return await contract.methods
      .swapETHForExactTokens(
        amount, //coin2.ammount
        [bnbAddress, tokenAddress],
        account,
        9876543210
      )
      .send({from: account, value: calcAmount})
      .then((res) => {
        return Promise.resolve("Swap Was Successful !");
      })
      .catch((err) => {
        return Promise.reject("Some Error Happend");
      });
  };
};
