import React, { useEffect, useState } from "react";
import "./RemoveLiquidity.css";
import RemoveLiquidityModal from "./RemoveLiquidityModal";
import Modal from "react-modal";
import toast from "react-hot-toast";
import useWeb3 from "../../hooks/use-web3";
import { addresses } from "../../modules/addresses";
import { toWei } from "../../modules/web3Wei";
import { useDispatch, useSelector } from "react-redux";
import { roundNumber } from "../../modules/formatNumbers";
import {
  removeLiquidityAction,
  removeLiquidityETHAction,
} from "store/token-actions";
import { tokenAddresses } from "modules/tokenAdresses";
import { Fragment } from "react";

const RemoveLiquidity = ({
  coin1,
  coin2,
  pairContract,
  swapContract,
  pairAddress,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { getAllowence, approve } = useWeb3();
  const account = useSelector((state) => state.auth.account);

  const dispatch = useDispatch();

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const removeLiquidity = async (input) => {
    let LPToken = input.current.value;

    await getAllowence(
      pairContract,
      account,
      addresses.swap_address,
      async (pairAllowence) => {
        if (pairAllowence < Number(toWei(LPToken, "ether"))) {
          await approve(
            pairContract,
            toWei("100000000000000", "tether"),
            account,
            addresses.swap_address,
            (res) => {
              toast.success(res);
            }
          );
        } else {
          console.log("No Need to Approve pair");
        }
      }
    );

    var now = new Date().getTime();

    if (coin1.address == tokenAddresses[0].address) {
      await dispatch(
        removeLiquidityETHAction(
          swapContract,
          // coin1.address,
          coin2.address,
          toWei(LPToken, "ether"),
          1,
          1,
          account,
          roundNumber((now + 300000) / 1000, 0)
        )
      )
        .then((res) => {
          closeModal();
          toast.success("Remove Liquidity was Successful !");
        })
        .catch((err) => {
          toast.error("Remove Liquidity Has Error !");
        });
    } else if (coin2.address == tokenAddresses[0].address) {
      await dispatch(
        removeLiquidityETHAction(
          swapContract,
          coin1.address,
          toWei(LPToken, "ether"),
          1,
          1,
          account,
          roundNumber((now + 300000) / 1000, 0)
        )
      )
        .then((res) => {
          closeModal();
          toast.success("Remove Liquidity was Successful !");
        })
        .catch((err) => {
          toast.error("Remove Liquidity Has Error !");
        });
    } else {
      await dispatch(
        removeLiquidityAction(
          swapContract,
          coin1.address,
          coin2.address,
          toWei(LPToken, "ether"),
          1,
          1,
          account,
          roundNumber((now + 300000) / 1000, 0)
        )
      )
        .then((res) => {
          closeModal();
          toast.success("Remove Liquidity was Successful !");
        })
        .catch((err) => {
          toast.error("Remove Liquidity Has Error !");
        });
    }

    // await swapContract.methods
    //   .removeLiquidity(
    //     coin1.address,
    //     coin2.address,
    //     toWei(LPToken, "ether"),
    //     1,
    //     1,
    //     account,
    //     roundNumber((now + 300000) / 1000, 0)
    //   )
    //   .send({ from: account })
    //   .then((res) => {
    //     closeModal();
    //     toast.success("Remove Liquidity was successfull !");
    //   })
    //   .catch((err) => {
    //     toast.success("Remove Liquidity Has Error !");
    //   });
  };

  return (
    <Fragment>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <RemoveLiquidityModal
            onCloseModal={closeModal}
            onRemoveLiquidity={removeLiquidity}
            coinsAddress={{ coin1: coin1.address, coin2: coin2.address }}
            contract={pairContract}
            address={pairAddress}
            LPName={coin1.name + "-" + coin2.name}
          />
        </Modal>
      )}

      <button onClick={openModal} className="main-button">
        Remove LP
      </button>
    </Fragment>
  );
};

export default RemoveLiquidity;
