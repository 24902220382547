import React, { useEffect, useState } from "react";
import PoolActions from "./PoolActions";
import PoolViewContract from "./PoolViewContract";
import { BsPlusLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ERC20_abi from "../../assets/files/ERC20.json";
import { coins } from "../../modules/coins";
import CoinField from "../coin/CoinField";
import { fromWei, toWei } from "../../modules/web3Wei";
import useBalance from "../../hooks/use-balance";
import CoinModal from "../coin/CoinModal";
import useContract from "../../hooks/use-contract";
import {
  getPairAddressFromFactory,
  getQoute,
  getReserves,
  getToken0,
} from "../../store/token-actions";
import pair_abi from "../../assets/files/Pair.json";
import factory_abi from "../../assets/files/Factory.json";
import { addresses } from "../../modules/addresses";
import "./PoolForm.css";
import useCoin from "../../hooks/use-coin";

const PoolForm = ({ swapContract }) => {
  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();
  const [pairContract, setPairContract] = useState(null);
  const [factoryContract, setFactoryContract] = useState(null);
  const [messageNotif, setMessageNotif] = useState("");
  const [pairAddress, setPairAddress] = useState();

  const {
    coin: coin1,
    setCoinHandler: setCoin1,
    setCoinValueHandler: setCoin1Value,
  } = useCoin(coins.BUSD);

  const {
    coin: coin2,
    setCoinValueHandler: setCoin2Value,
    setCoinHandler: setCoin2,
  } = useCoin(coins.BULC);

  const { getContract: getContract } = useContract();

  useEffect(() => {
    getContract(ERC20_abi.abi, coin1.address, (contract) => {
      setCoin1Value("contract", contract);
    });

    getContract(ERC20_abi.abi, coin2.address, (contract) => {
      setCoin2Value("contract", contract);
    });

    getContract(factory_abi.abi, addresses.factory_address, (contract) => {
      setFactoryContract(contract);
    });
  }, []);

  const getPairContract = async () => {
    await getContract(pair_abi.abi, pairAddress, (contract) => {
      setPairContract(contract);
    });
  };

  useEffect(() => {
    if (pairAddress) {
      getPairContract();
      setMessageNotif("");
    } else {
      setPairContract(null);
    }
  }, [pairAddress]);

  const updateTokenBalances = () => {
    getCoin1Balance(coin1.contract, account);
    getCoin2Balance(coin2.contract, account);
  };

  const { balance: coin1Balance, getBalance: getCoin1Balance } = useBalance();
  const { balance: coin2Balance, getBalance: getCoin2Balance } = useBalance();

  const getPairAddress = async () => {
    dispatch(
      getPairAddressFromFactory(factoryContract, coin1.address, coin2.address)
    )
      .then((res) => {
        if (res == "0x0000000000000000000000000000000000000000") {
          setMessageNotif("Pair Address Does not exist");
          setPairAddress("");
        } else {
          setPairAddress(res);
        }
      })
      .catch((err) => {
        console.log("err in get pair address");
      });
  };

  useEffect(() => {
    if (coin1.contract && coin2.contract && account) {
      updateTokenBalances();
      getPairAddress();
    }
  }, [coin1.contract, coin2.contract, account]);

  useEffect(() => {
    setCoin2Value("balance", coin2Balance);
  }, [coin2Balance]);

  useEffect(() => {
    setCoin1Value("balance", coin1Balance);
  }, [coin1Balance]);

  const changeCoin1AmountHandler = async (data) => {
    if (!data.value || data.value <= 0) {
      setCoin2Value("amount", "");
      return;
    }
    setCoin1Value("amount", data.value);

    if (pairContract) {
      let token0 = await dispatch(getToken0(pairContract));
      await dispatch(getReserves(pairContract)).then(async (res) => {
        if (res.reserve1 <= 0 || res.reserve0 <= 0) return;
        let coin1Reserve;
        let coin2Reserve;
        if (token0 == coin1.address) {
          coin1Reserve = res.reserve0;
          coin2Reserve = res.reserve1;
        } else {
          coin1Reserve = res.reserve1;
          coin2Reserve = res.reserve0;
        }
        await dispatch(
          getQoute(
            swapContract,
            toWei(data.value, "ether"),
            coin1Reserve, //coin1 reserve
            coin2Reserve //coin2 reserve
          )
        ).then((res2) => {
          setCoin2Value("amount", fromWei(res2, "ether"));
        });
      });
    }
  };

  const changeCoin2AmountHandler = async (data) => {
    if (!data.value || data.value <= 0) {
      setCoin1Value("amount", "");
      return;
    }

    setCoin2Value("amount", data.value);

    if (pairContract) {
      let token0 = await dispatch(getToken0(pairContract));
      await dispatch(getReserves(pairContract)).then(async (res) => {
        if (res.reserve1 <= 0 || res.reserve0 <= 0) return;

        let coin1Reserve;
        let coin2Reserve;
        if (token0 == coin2.address) {
          coin1Reserve = res.reserve0;
          coin2Reserve = res.reserve1;
        } else {
          coin1Reserve = res.reserve1;
          coin2Reserve = res.reserve0;
        }

        await dispatch(
          getQoute(
            swapContract,
            toWei(data.value, "ether"),
            coin1Reserve,
            coin2Reserve
          )
        ).then((res2) => {
          setCoin1Value("amount", fromWei(res2, "ether"));
        });
      });
    }
  };

  const changeCoin1Handler = (data) => {
    setCoin1(data);
  };

  const changeCoin2Handler = (data) => {
    setCoin2(data);
  };

  const supplyLPHandler = () => {
    updateTokenBalances();
    getPairAddress();
    setCoin1Value("amount", "");
    setCoin2Value("amount", "");
  };

  return (
    <div>
      {" "}
      <div className="pool__containers">
        <PoolViewContract pairAddress={pairAddress} />
        <CoinField
          coinBalance={coin2.balance}
          tokenImage={coin2.image}
          tokenName={coin2.name}
          tokenContract={coin2.contract}
          tokenAddress={coin2.address}
          calculatedAmount={coin2.amount}
          onChangeInputHandler={changeCoin2AmountHandler}
        />
        <CoinModal onSetSelectedTokenData={changeCoin2Handler} />
        <svg
          className="pool-icon"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.7673 11.4H11.8473V20.4H9.24727V11.4H0.367266V9H9.24727V0H11.8473V9H20.7673V11.4Z"
            fill="#C5C1C0"
          />
        </svg>
        {/* <BsPlusLg className="pool-icon" /> */}
        <CoinField
          coinBalance={coin1.balance}
          tokenImage={coin1.image}
          tokenName={coin1.name}
          tokenContract={coin1.contract}
          tokenAddress={coin1.address}
          calculatedAmount={coin1.amount}
          onChangeInputHandler={changeCoin1AmountHandler}
        />
        <CoinModal onSetSelectedTokenData={changeCoin1Handler} />
      </div>
      <PoolActions
        coin1={coin1}
        coin2={coin2}
        pairContract={pairContract}
        swapContract={swapContract}
        pairAddress={pairAddress}
        onSupplyLPHandler={supplyLPHandler}
      />
      {messageNotif && (
        <p className="message-noification">Warning :{messageNotif}</p>
      )}
    </div>
  );
};

export default PoolForm;
