import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  // getEndWitdrawTimestamp,
  getEndStakeTimestampAction,
} from "store/admin-actions";

const NewStakeTimer = ({ stakeContract2 }) => {
  const launch = useRef();
  const [timer, setTimer] = useState({});
  const dispatch = useDispatch();
  const [dedline, setDedline] = useState(true);

  const countdownTimer = (timestamp) => {
    // if (timestamp == 0) {
    //   setDedline(true);
    //   return;
    // }
    launch.current = setInterval(() => {
      let time = getTimeFromDate(timestamp);
      setTimer(time);
    }, 1000);
  };

  // const getEndStakeTimer = () => {
  //   dispatch(getEndWitdrawTimestamp(stakeContract2)).then((res) => {
  //     countdownTimer(Number(res) * 1000);
  //   });
  // };

  const getEndStakeTimer = () => {
    dispatch(getEndStakeTimestampAction(stakeContract2)).then((res) => {
      countdownTimer(Number(res) * 1000);
    });
  };

  const getTimeFromDate = (timestamp) => {
    var now = new Date().getTime();
    var timeleft = timestamp - now;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;

    if (timeleft > 0) {
      setDedline(false);
      hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    } else {
      setDedline(true);
      clearInterval(launch.current);
    }

    let time = {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };

    return time;
  };

  useEffect(() => {
    if (stakeContract2) {
      getEndStakeTimer();
    }
  }, [stakeContract2]);

  return (
    <>
      {!dedline && (
        <div className="start-withdraw-timestamp" key="new-stake-timer">
          <h2>Pools Close In</h2>
          <div className="deadline-timer__times">
            <div>
              <p>{timer.hours}</p>
              <p>H</p>
            </div>
            <p>:</p>

            <div>
              <p>{timer.minutes}</p>
              <p>M</p>
            </div>
            <p>:</p>

            <div>
              <p>{timer.seconds}</p>
              <p>S</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NewStakeTimer;
