import React, { useEffect } from "react";
import useBalance from "../hooks/use-balance";
import { roundNumber } from "../modules/formatNumbers";
// import { usedNetworkId } from "../modules/networks";
import { fromWei } from "../modules/web3Wei";
import { useSelector } from "react-redux";
import TokenBalanceImage from "../assets/images/TokenBalanceImage.svg"

const LPToeknBalance = ({ contract, LPName }) => {
  const account = useSelector((state) => state.auth.account);
  // const networkId = useSelector((state) => state.wallet.networkId);
  const { balance: LPTokenBalance, getBalance: getLPBalance } = useBalance();

  useEffect(() => {
    if (contract && account) {
      getLPBalance(contract, account);
    }
  }, [contract, account]);

  return (
    <div className="LP-token-balance">
      <img src={TokenBalanceImage}/>
      <p>BLP Balance: {roundNumber(fromWei(LPTokenBalance, "ether"), 2)}</p>
      {/* <p>Balance :</p>
      <p>
        {roundNumber(fromWei(LPTokenBalance, "ether"), 5)}{" "}
        {LPName || "BUSD-BULC LP"}
      </p> */}
    </div>
  );
};

export default LPToeknBalance;
