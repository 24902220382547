import { shortAccountAddress } from "modules/formatNumbers";
import React from "react";
import { useState } from "react";
import { BiCopy } from "react-icons/bi";
import { fromWei } from "modules/web3Wei";

const TopTenItem = ({ itemAddress, itemAmount }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboardHandler = (address) => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="topten-item">
      <p>{shortAccountAddress(itemAddress, 7)}</p>
      <button onClick={() => copyToClipboardHandler(itemAddress)}>
        <BiCopy />
        {copied ? <span>Copied.</span> : null}
      </button>
      <p>{fromWei(itemAmount)} LP</p>
    </div>
  );
};

export default TopTenItem;
