import React from "react";
import ERC20_abi from "../../assets/files/ERC20.json";
import useContract from "../../hooks/use-contract";
import { tokenAddresses } from "modules/tokenAdresses";

const CoinModalTokenList = ({ onSetTokenData }) => {
  const { getContract } = useContract();

  const setSelectedTokenHandler = async (token) => {
    await getContract(ERC20_abi.abi, token.address, async (contract) => {
      let data = {
        name: token.name,
        contract: contract,
        address: token.address,
        amount: 0,
        image: token.logoURI,
      };

      onSetTokenData(data);
    });
  };

  return (
    <ul className="top-tokens">
      {tokenAddresses.map((token) => (
        <li
          key={token.name}
          className="top-token__item"
          onClick={() => setSelectedTokenHandler(token)}
        >
          <img src={token.logoURI} />
          <div>
            <p>{token.symbol}</p>
            <p>{token.name}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CoinModalTokenList;
