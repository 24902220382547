export const addresses = {
  swap_address: "0xF6C637ffcDDbdD0BA8440CaCa00483A830005Dd1", //test
  BUSD_address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", //main
  BULC_address: "0x33A12CEe993da069BF7EBAB3D7423e25278acf61", //main
  pair_address: "0x24B411e4CeFbE88B84Ed5d833870A008a0e543eb", //main
  staking_address: "0x2964BF9E8b888C01F3e56b00B65b3dAf94C5D15F", //main
  staking2_address: "0xaC5a4C4Dd6D51bA313a3f82D5392AD31382B8f02", //mian
  staking_BULC_address: "0x1a40F71B34c1708aF527CB1375A340889Acc8777", //main
  factory_address: "0x5A9FC5a9695E89Dc0EB5cbedcbfA652D60BeA7d4", //main
};

//test
// export const addresses = {
//   swap_address: "0xF6C637ffcDDbdD0BA8440CaCa00483A830005Dd1", //test
//   BUSD_address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", //main
//   BULC_address: "0x221f9C08B84c696cA54FdA3dB5C76878743b8fEb", //test
//   pair_address: "0xC1a3376E30692DC2B72D8B779C3d53aB15847B47", //test
//   staking_address: "0x2964BF9E8b888C01F3e56b00B65b3dAf94C5D15F", //main
//   staking2_address: "0xff35953Cc0fDeF55290Ce387171538C278e7CC38", //mian
//   staking_BULC_address: "0xba7f6B3ae967d87a8d6712d94D5fB997393E0c95", //test
//   factory_address: "0x5A9FC5a9695E89Dc0EB5cbedcbfA652D60BeA7d4", //main
// };
